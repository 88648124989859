
<template>
  <div>
    <v-row>
      <template v-for="(item, index) in items">
        <v-col md="3" :key="index" v-if="returnAbility(item.ability)">
          <v-card elevation="3" class="mt-5 mx-5"  style="background-color: var(--card-report-background) !important;
           color: white !important; border-radius: 15px"  @click="$router.push(item.routerLink)">
            <v-card-title class="justify-center">
              <h2>{{ $t(item.name) }}</h2>

            </v-card-title>
            <v-card-actions class="justify-center">
              <!-- <v-icon>{{ item.icon }}</v-icon> -->

            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-chart-bar",
          name: "Trial Balance",
          routerLink: "/trialBalance",
          ability: "account:trailbalance",
        },
        {
          icon: "mdi-chart-bar",
          name: "Ledger",
          routerLink: "/ledger",
          ability: "account:statement",
        },
        // {
          // icon: "mdi-chart-bar",
        //   name: "Balance",
        //   routerLink: "/balance",
        //   // ability: "",
        // },
        {
          icon: "mdi-chart-bar",
          name: "Taxes",
          routerLink: "/taxesReport",
          ability: "tax:report",
        },
        {
          icon: "mdi-chart-bar",
          name: "Digital Wallet",
          routerLink: "/digitalWalletReport",
          ability: "digital_wallet_record:report",
        },
      ],
    };
  },
};
</script>
 
<style>
</style>